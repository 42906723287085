<template>
    <div class="setPayPwdMask">
        <div class="bigbox">
            <div class="login-box">
                <div class="popup-title">
                    <p v-text="$t('setpay.title')">设置支付密码</p>
                    <img src="../assets/icon/delete.png" class="click" @click.stop="fnClose">
                </div>
                <div class="popup-body">
                    <div class="choice-box">
                        <p v-text="$t('setpay.head')">抬头类型</p>
                        <div class="click" v-if="userinfo && userinfo.mobile" @click.stop="auth_type='mobile'">
                            <img src="../assets/icon/yes-dot.png" v-if="auth_type=='mobile'">
                            <img src="../assets/icon/no-dot.png" v-else>
                            <p v-text="$t('setpay.phone')">手机验证</p>
                            <p v-text="userinfo.mobile">132 9283 2103</p>
                        </div>
                        <div class="click" v-if="userinfo && userinfo.email" @click.stop="auth_type='email'">
                            <img src="../assets/icon/yes-dot.png" v-if="auth_type=='email'">
                            <img src="../assets/icon/no-dot.png" v-else>
                            <p v-text="$t('setpay.postbox')">邮箱验证</p>
                            <p v-text="userinfo.email">SJ13291@163.com</p>
                        </div>
                    </div>
                    <div class="input-box">
                        <p v-text="$t('setpay.input')">输入验证码</p>
                        <div>
                            <input type="text" maxlength="4" v-model="mobile_code">
                            <div class="click" @click.stop="fnGetSmsCode()" v-text="smsBtnText">获取验证码</div>
                        </div>
                    </div>
                    <div class="input-box">
                        <p v-text="$t('setpay.password')">输入密码</p>
                        <div>
                            <input type="password" maxlength="6" v-model="password">
                        </div>
                    </div>
                    <div class="input-box">
                        <p v-text="$t('setpay.confirm')">确认密码</p>
                        <div>
                            <input type="password" maxlength="6" v-model="repassword">
                        </div>
                    </div>
                </div>
                <div class="botton-box click" v-text="$t('setpay.botton')" @click.stop="setPayPwdSubmit">提交</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SetPayPwdMask',
    data() {
        return {
            userinfo: null,
            auth_type: "mobile",
            smsBtnText: this.$t('login.getcode'), // 获取验证码
            sendSmsIng: false,
            timer: null,
            password: null,
            repassword: null,
            mobile_code: null,
        }
    },
    created() {
        var userinfo = sessionStorage.getItem('userinfo');
        userinfo = JSON.parse(userinfo);
        this.userinfo = userinfo;
    },
    methods: {
        /**
         * 设置支付密码提交
         */
        setPayPwdSubmit() {
            var self = this;

            if (!self.mobile_code || self.mobile_code.length != 4) return this.common.showMessage(self.$t('bindEmail.code_wrong'), "error");//验证码错误
            if (!self.password || self.password.length != 6) return this.common.showMessage(self.$t('setpay.six_password'), "error");//請輸入六位密碼
            if (self.password != self.repassword) return this.common.showMessage(self.$t('password.no_alike'), "error");//两次输出的密码不一致

            self.common.httpPost("/api/user/updatePayPassword", {
                password: self.password,
                mobile_code: self.mobile_code,
                email_code: self.mobile_code,
                auth_type: self.auth_type
            }).then((ret) => {
                self.common.showMessage(ret.msg, ret.code == 1 ? "success" : "error");
                if (ret.code == 1) {
                    clearInterval(self.timer);
                    self.smsBtnText = this.$t('login.getcode');
                    self.sendSmsIng = false;
                    self.password = null;
                    self.repassword = null;
                    self.mobile_code = null;
                    self.$emit("setPayPwdSuccess");
                }
            })
        },
        fnClose() {
            this.$emit('close');
        },
        /** 获取验证码 */
        fnGetSmsCode() {
            var self = this;
            if (self.sendSmsIng) return false;
            if (self.auth_type == 'mobile') {
                self.httpSendSms(self.userinfo.mobile).then(ret => {
                    self.common.showMessage(ret.msg, ret.code == 1 ? "success" : "error");
                    if (ret.code == 1) self.fnSmsBtnTim();
                })
            } else if (self.auth_type == 'email') {
                self.httpSendEmail(self.userinfo.email).then(ret => {
                    self.common.showMessage(ret.msg, ret.code == 1 ? "success" : "error");
                    if (ret.code == 1) self.fnSmsBtnTim();
                })
            }
        },
        /**
         * 发送短信验证码
         * @param  {[type]}   mobile 手机号码
         * @param  {Function} fn     回调函数
         * @return {[type]}          [description]
         */
        httpSendSms: function(mobile) {
            return this.common.httpPost("/api/ajax/sendMobileMessage", {
                mobile: mobile
            })
        },
        httpSendEmail: function(email) {
            return this.common.httpPost("/api/ajax/sendEmailMessage", {
                email: email
            })
        },
        // 短信按钮计时
        fnSmsBtnTim: function() {
            var self = this;
            var time = 60;
            self.timer = setInterval(function() {
                if (time > 0) {
                    self.sendSmsIng = true;
                    self.smsBtnText = time + self.$t('changepassword.after');
                    time--;
                } else {
                    self.smsBtnText = self.$t('login.getcode');
                    self.sendSmsIng = false;
                    clearInterval(self.timer);
                }
            }, 1000);
        },
    },
    components: {}
}
</script>
<style>
</style>
<style scoped>
.bigbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
}

.login-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 536px;
    width: 400px;
    background: #FFFFFF;
    border-radius: 2px;
}

.popup-title {
    height: 50px;
    background: rgba(247, 246, 246, 1);
    border-radius: 2px 2px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popup-title p {
    font-size: 16px;
    font-weight: 500;
    color: rgba(153, 153, 153, 1);
    line-height: 24px;
    margin-left: 30px;
}

.popup-title img {
    height: 12px;
    width: 12px;
    margin-right: 30px;
}

.popup-body {
    margin: 0px 30px;
}

.choice-box {
    margin-top: 30px;
    margin-bottom: 30px;
}

.choice-box p {
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 16px;
    margin-right: 27px;
}

.choice-box div {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.choice-box div img {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.input-box {
    margin-bottom: 20px;
}

.input-box p {
    font-size: 14px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 16px;
}

.input-box>div {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgba(221, 221, 221, 0.7);
    margin-top: 10px;
}

.input-box div input {
    flex: 1;
    padding: 10px 15px;
}

.input-box div div {
    height: 34px;
    width: 106px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: rgba(41, 127, 171, 1);
    line-height: 34px;
    border-left: 1px solid rgba(221, 221, 221, 0.7);
}

.input-box>input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(221, 221, 221, 0.7);
    padding: 10px 15px;
}

.botton-box {
    width: 160px;
    height: 35px;
    background: rgba(41, 127, 171, 1);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 35px;
    text-align: center;
    margin: 40px auto;
}
</style>