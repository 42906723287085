<template>
    <div class="payPasswordMask">
        <div class="bigbox">
            <div class="login-box">
                <div class="password-box">
                    <p v-text="$t('pay.title')">支付密码</p>
                    <img src="../assets/icon/delete.png" class="click" @click.stop="fnClose">
                </div>
                <div class="password">
                    <input v-for="(item,index) in inputList" v-model="item.val" type="password" maxlength="1" @keyup="nextFocus($event,index)" @keydown="changeValue(index)" ref="pwdInput">
                </div>
                <p class="click" @click.stop="fnForget" v-text="$t('pay.forget')">忘记密码</p>
                <div class="botton-box">
                    <el-button type="primary" @click="" v-text="$t('pay.botton')" @click.stop="fnSubmit">提交</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'PayPasswordMask',
    data() {
        return {
            inputList: [{ val: "" }, { val: "" }, { val: "" }, { val: "" }, { val: "" }, { val: "" }]
        }
    },
    created() {},
    methods: {
        fnClose() {
            this.$emit('close');
        },
        fnForget() {
            this.$emit('fnForget');
        },
        fnSubmit() {
            var password = '';
            for (var i = 0; i < this.inputList.length; i++) {
                if (!this.inputList[i].val) return this.common.showMessage(this.$t('password.password_placeholder'), 'error');
                password = password + this.inputList[i].val;
            }
            this.$emit('payPasswordSubmit', password)
        },
        /*对焦到下一个input框去*/
        nextFocus(el, index) {
            var dom = this.$refs.pwdInput,
                currInput = dom[index],
                nextInput = dom[index + 1],
                lastInput = dom[index - 1];
            if (el.keyCode != 8) {
                if (index < (this.inputList.length - 1)) {
                    nextInput.focus();
                } else {
                    currInput.blur();
                }
            } else {
                if (index != 0) {
                    lastInput.focus();
                }
            }

        },
        changeValue(index) {
            this.inputList[index].val = "";
        },
    },
    components: {}
}
</script>
<style>
</style>
<style scoped>
.bigbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
}

.botton-box button {
    width: 160px;
}

.login-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 210px;
    width: 320px;
    background: #FFFFFF;
    border-radius: 2px;
}

.password-box {
    margin-top: 7px;
    display: flex;
    align-items: center;
    margin-bottom: 27px;
}

.password-box p {
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 24px;
    margin-left: 118px;
}

.password-box img {
    height: 12px;
    width: 12px;
    margin-left: 96px;
}

.password {
    width: 240px;
    margin: auto;
    display: flex;
    border: 1px solid rgba(230, 230, 230, 0.7);
}

.password input {
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 40px;
    text-align: center;
    border-right: 1px solid rgba(230, 230, 230, 0.7);
}

.password input:last-child {
    border-right: none;
}

.password p:last-child {
    border-right: 0;
}

.login-box>p {
    font-size: 12px;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 16px;
    text-align: right;
    margin-right: 43px;
    margin-top: 7px;
}

.botton-box {
    width: 160px;
    height: 35px;
    background: rgba(41, 127, 171, 1);
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 35px;
    text-align: center;
    margin: 18px auto;
}
</style>