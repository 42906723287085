
<template>
  <!-- 添加 编辑发票页面 -->
  <div class="invoiceMask">
    <div class="bigbox">
      <el-form ref="ruleForm">
        <div class="login-box">
          <div class="popup-title">
            <p v-text="$t('inv.title')"></p>
            <img src="../assets/icon/delete.png" class="click" @click.stop="fnClose" />
          </div>
          <div class="popup-body">
            <!-- 发票类型 -->
            <div class="choice-box">
              <p v-text="$t('inv.bill_type')"></p>
              <div>
                <div class="click" @click.stop="ruleForm.bill_type = 1">
                  <img v-if="ruleForm.bill_type==1" src="../assets/icon/yes-dot.png" />
                  <img v-else src="../assets/icon/no-dot.png" />
                  <p v-text="$t('inv.bill_ordinary')"></p>
                </div>
                <div class="click" @click.stop="ruleForm.bill_type = 2">
                  <img v-if="ruleForm.bill_type==2" src="../assets/icon/yes-dot.png" />
                  <img v-else src="../assets/icon/no-dot.png" />
                  <p v-text="$t('inv.bill_major')"></p>
                </div>
              </div>
            </div>

            <!-- 抬头类型 -->
            <div class="choice-box">
              <p v-text="$t('inv.type')"></p>
              <div>
                <div class="click" @click.stop="ruleForm.type = 1">
                  <img v-if="ruleForm.type==1" src="../assets/icon/yes-dot.png" />
                  <img v-else src="../assets/icon/no-dot.png" />
                  <p v-text="$t('inv.group')"></p>
                </div>
                <div class="click" @click.stop="ruleForm.type = 2">
                  <img v-if="ruleForm.type==2" src="../assets/icon/yes-dot.png" />
                  <img v-else src="../assets/icon/no-dot.png" />
                  <p v-text="$t('inv.personal')"></p>
                </div>
              </div>
            </div>

            <div class="numb-box">
              <!-- 发票抬头 -->
              <div class="input-box">
                <p v-text="$t('inv.head')"></p>
                <el-input
                  v-model="ruleForm.title"
                  :placeholder="$t('inv.head_input')"
                  maxlength="50"
                ></el-input>
              </div>
              <!-- 税号 -->
              <div class="input-box" v-if="ruleForm.type == 1">
                <p v-text="$t('inv.numb')"></p>
                <el-input v-model="ruleForm.code" :placeholder="$t('inv.numb_input')"></el-input>
              </div>
              <!-- 开户行账号 -->
              <div class="input-box">
                <p v-text="$t('inv.bank')">
                  <span v-text="$t('inv.choose')"></span>
                </p>
                <el-input v-model="ruleForm.bank" :placeholder="$t('inv.bank_input')"></el-input>
              </div>

              <!-- 地址电话 -->
              <div class="input-box">
                <p v-text="$t('inv.adderss')">
                  <span v-text="$t('inv.choose')"></span>
                </p>
                <el-input v-model="ruleForm.address_mobile" :placeholder="$t('inv.adderss_input')"></el-input>
              </div>

              <!-- 备注 -->
              <div class="input-box">
                <p v-text="$t('inv.remarks')">
                  <span v-text="$t('inv.choose')"></span>
                </p>
                <el-input v-model="ruleForm.remark" :placeholder="$t('inv.remarks_input')"></el-input>
              </div>
            </div>
          </div>
          <div class="botton-box">
            <el-button type="primary" @click="submitForm()" v-text="$t('inv.botton')"></el-button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { Form, FormItem, Cascader } from "element-ui";

export default {
  name: "InvoiceMask",
  data() {
    return {
      ruleForm: {
        bill_type: 1, //发票类型 1普通发票 2专用发票
        type: 1, // 抬头类型 1企业单位 2个人
        title: null, //发票抬头
        code: null, // 税号
        remark: null, // 备注
        address_mobile: null, //地址电话
        bank: null // 开户行和账号
      }
    };
  },
  props: {
    editBill: {
      type: Object
    }
  },
  created() {
    if (this.editBill) {
      this.ruleForm.type = this.editBill.type;
      this.ruleForm.bill_type = this.editBill.bill_type;
      this.ruleForm.title = this.editBill.title;
      this.ruleForm.code = this.editBill.number;
      this.ruleForm.address_mobile = this.editBill.address;
      this.ruleForm.remark = this.editBill.remark;
      this.ruleForm.bank = this.editBill.bank_info;
    }
  },
  methods: {
    submitForm() {
      var self = this;
      if (!self.ruleForm.title)
        return self.common.showMessage(self.$t("inv.bill_head"), "error");
      if (self.ruleForm.type == 1 && !self.ruleForm.code)
        return self.common.showMessage(self.$t("inv.bill_num"), "error");
      if (self.ruleForm.bill_type == 2 && !self.ruleForm.bank)
        return self.common.showMessage(self.$t("inv.message_text"), "error");
      if (self.ruleForm.bill_type == 2 && !self.ruleForm.address_mobile)
        return self.common.showMessage(self.$t("inv.mobile_text"), "error");

      var url = "/api/user/addBill";
      var params = {
        bill_type: self.ruleForm.bill_type,
        type: self.ruleForm.type,
        title: self.ruleForm.title,
        number: self.ruleForm.code,
        remark: self.ruleForm.remark,
        title: self.ruleForm.title,
        address: self.ruleForm.address_mobile,
        bank_info: self.ruleForm.bank
      };

      if (self.editBill) {
        url = "/api/user/editBill";
        params.id = self.editBill.id;
      }

      self.common.httpPost(url, params).then(ret => {
        self.common.showMessage(ret.msg, ret.code == 1 ? "success" : "error");
        if (ret.code == 1) {
          if (self.editBill) self.$emit("editSuccess", ret.data);
          else self.$emit("addSuccess", ret.data);
        }
      });
    },
    fnClose() {
      this.$emit("close");
    }
  },
  components: {
    "el-cascader": Cascader,
    "el-form": Form,
    "el-form-item": FormItem
  }
};
</script>
<style>
</style>
<style scoped>
.bigbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.login-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 600px;
  width: 580px;
  background: #ffffff;
  border-radius: 2px;
}

.popup-title {
  height: 50px;
  background: rgba(247, 246, 246, 1);
  border-radius: 2px 2px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup-title p {
  font-size: 16px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 24px;
  margin-left: 30px;
}

.popup-title img {
  height: 12px;
  width: 12px;
  margin-right: 30px;
}

.popup-body {
  margin: 10px 0px 0px 30px;
}

.choice-box {
  margin-top: 20px;
}

.choice-box p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 16px;
}

.choice-box div {
  display: flex;
}

.choice-box div div {
  margin-top: 10px;
  margin-right: 40px;
  display: flex;
  align-items: center;
}

.choice-box img {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.numb-box {
  display: flex;
  flex-wrap: wrap;
}

.input-box {
  margin-right: 20px;
  margin-top: 30px;
}

.input-box p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 16px;
}

.input-box .el-input {
  height: 16px;
  width: 250px;
  border-radius: 4px;
  /* border: 1px solid rgba(221, 221, 221, 0.7); */
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 15px;
  /* padding: 10px 15px; */
  margin-top: 10px;
}

.input-box span {
  font-size: 14px;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 16px;
}

.botton-box {
  width: 160px;
  height: 35px;
  background: rgba(41, 127, 171, 1);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 35px;
  text-align: center;
  margin: 60px auto;
}

.botton-box button {
  width: 160px;
  height: 35px;
}
</style>